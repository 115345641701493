import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import theme from './theme';
import {ThemeProvider} from "@mui/material";
import './App.css';
import MapScreen from './components/Map';


function App() {
  return (

    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path='/' element={<MapScreen context="explorade" />}/>
            <Route path='explorade' element={<MapScreen context="explorade" />}/>
            <Route path='parcoursmer' element={<MapScreen context="parcoursmer" />}/>
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
