import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import parse from 'html-react-parser';


export interface PoiInfoDialogProps {
  poi: any;
  category: any;
  context: string;
  open: boolean;
  onClose: () => void;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const CustomDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 18,
            top: 15,
            color: '#FFF',
            backgroundColor: '#004272',
            width: 20,
            height: 20
          }}
        >
          <CloseIcon
            sx={{
              width: 16
            }}
          />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function PoiInfoDialog(props: PoiInfoDialogProps) {
  const poi = props.poi;
  const category = props.category;
  const context = props.context;
  const onClose = props.onClose;
  const open = props.open;

  const [loading, setLoading] = useState<boolean>(false);
  const [longDescription, setLongDescription] = useState<string>('');

  const handleClose = () => {
    onClose();
  };

  const getFullPoiData = () => {
    setLoading(true);
    fetch(poi.long_description_url
      ,{
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
      .then(function(response){
        return response.json();
      })
      .then(function(myJson) {
        if(myJson.full_description) {
          setLongDescription(myJson.full_description.replace(/href/g, "target='_blank' href"));
        }

        setLoading(false);
      });
  };

  useEffect(()=>{
    getFullPoiData()
  },[]);

  return (
    <Dialog
      onClose={handleClose}
      className="poi-info-dialog"
      aria-labelledby="customized-dialog-title"
      open={open}
      sx={{
         "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "640px",  // Set your width here
            margin: "1em",
          },
        },
      }}
    >
      <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Box sx={{ paddingRight:'15px', paddingLeft:'15px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          {poi.name}
        </Box>
      </CustomDialogTitle>
      <DialogContent dividers sx={{ p: 0, minWidth: 300, borderTop: 0 }}>
        <img src={poi.image_medium_url} />
        <Box sx={{ p:2, pt:1 }}>
          <Typography variant="h5" component="h5">
            Localisation
          </Typography>
          {poi.lat.toFixed(6)}, {poi.lng.toFixed(6)}


          {category ?
            <Stack direction="row" alignItems="center" gap={1} sx={{fontWeight: 700, fontSize: 15, mt:1 }}>
              <i
                className={`ic_${context}_${category.icon}`}
                style={{
                fontSize:24,
                color: '#FFF',
                backgroundColor: category.color,
                borderRadius: '50%'
              }}
              />
              <span style={{ color: category.color }}>
            { category.name }
          </span>
            </Stack>
            : null}


          {
            poi.short_description ?
              <div>
                {parse(poi.short_description)}
              </div>
              : null
          }

          {loading ?
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress sx={{ width: 50, height: 50}} />
            </Box>
            : parse(longDescription)
          }

        </Box>
      </DialogContent>
    </Dialog>
  );
}