import React, { useRef, useState, useMemo, useEffect } from 'react';
import {
  Box,
  Fab,
  Stack,
} from '@mui/material';
import Map, {Marker, Source, Layer, MapRef, GeolocateControl} from 'react-map-gl'
import '../styles/map.css';
import {Feature, FeatureCollection, Geometry, GeoJsonProperties} from "geojson";
import CategoryFilterDialog from './CategoryFilterDialog';
import LanguageSelectorDialog from './LanguageSelectorDialog';
import PoiInfoCard from './PoiInfoCard';
import PoiInfoDialog from './PoiInfoDialog';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import screenfull from 'screenfull';


export interface MapScreenProps {
  context: string;
}

export default function MapScreen(props: MapScreenProps) {

  const mapRef: any = useRef<MapRef>(null);
  const [lng] = useState(-3.36326761);
  const [lat] = useState(47.7217971);
  const [zoom] = useState(11);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [lang, setLang] = useState<string>('fr');
  const [popupInfoFeature, setPopupInfoFeature] = useState<any>(null);
  const [context, setContext] = useState<string>(props.context != null ? props.context : "explorade");
  const [categories, setCategories] = useState<any>([]);
  const [enabledCategoriesIndex, setEnabledCategoriesIndex] = useState<any>([]);
  const [categoriesIndexById, setCategoriesIndexById] = useState<any>({});
  const [openLanguageSelectorDialog, setOpenLanguageSelectorDialog] = useState<boolean>(false);
  const [poiInfoDialogOpen, setPoiInfoDialogOpen] = useState<boolean>(false);

  const [pois,setPois]=useState<any>([]);

  const [isFullscreen, setFullscreen] = useState<boolean>(screenfull.isFullscreen);

  const getData=()=>{
    fetch(context === 'explorade' ? 'https://explorade.bookbeo.com/api/explorade_data.json?lang=' + lang : 'https://parcoursmer.bookbeo.com/api/v1/mdlm_data'
      ,{
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
      .then(function(response){
        return response.json();
      })
      .then(function(myJson) {
        setPois(myJson.pois);
        let poi_types = context === 'explorade' ? myJson.poi_types : myJson.poiTypes;
        let categories = poi_types.map((item) => {
          return {
            ...item,
            enabled: true
          }
        });
        setCategories(categories);

        let categoriesIndexByIdObj = {}
        poi_types.forEach((item, i) => {
          categoriesIndexByIdObj[item.tid] = i;
        });
        setCategoriesIndexById(categoriesIndexByIdObj);
        setDataLoaded(true);
      });
  };

  const handleTopLeftControlClick = () => {
    // FullScreen mode
    if (screenfull.isEnabled) {
      setFullscreen(!isFullscreen);
      screenfull.toggle();
    }
  };

  const handleTopRightControlClick = () => {
    // Explorade : language switch
    // Parcours mer : open video playlist on Youtube
    if(context === 'explorade') {
      setOpenLanguageSelectorDialog(true);
    }
    else {
      window.open('https://www.youtube.com/playlist?list=PLBTE__T0breb9zykZYpa-Rrq4fzql0ndA', '_blank');
    }
  };

  const onCloseLanguageSelectorDialog = () => {
    setOpenLanguageSelectorDialog(false);
  };

  const selectNewLang = (newLang) => {
    setLang(newLang);
  };

  useEffect(()=>{
    getData()
  },[lang]);


  const onCategoryFilterChange = (enabledCategoriesByIndex) => {
    enabledCategoriesByIndex.forEach((item, i) => {
      categories[i].enabled = item;
    });
    setCategories(categories);
    setEnabledCategoriesIndex(enabledCategoriesByIndex);
  };

  const popupInfoClose = () => {
    setPopupInfoFeature(null);
  };

  const openPoiInfoDialog = () => {
    setPoiInfoDialogOpen(true);
  };

  const onPoiInfoDialogClose = () => {
    setPoiInfoDialogOpen(false);
  };

  const renderPois = useMemo(
    () => {
      const poisPointFeatures = pois.map((poi, i) => {
        return {
          type: "Feature",
          properties: {
            title: poi.name,
            index: i,
            icon: (typeof poi.type === 'undefined' ? 'default' : categories[categoriesIndexById[poi.type]].icon),
            catIndex: (typeof poi.type === 'undefined' ? -1 : categoriesIndexById[poi.type])
          },
          geometry: {
            type: "Point",
            coordinates: [poi.lng, poi.lat]
          }
        }
      });

      return (
        poisPointFeatures.map((feature, i) => {
          return (
            <Marker
              key={`poimarker-${i}`}
              longitude={feature.geometry.coordinates[0]}
              latitude={feature.geometry.coordinates[1]}
              offset={[0, -18]}
              style={{ display: (feature.properties.catIndex >= 0 && categories[feature.properties.catIndex].enabled == false) ? 'none' : 'block' }}
              onClick={e => {
                  e.originalEvent.stopPropagation();
                  setPopupInfoFeature(feature);
                }}
            >
              <div className={popupInfoFeature && (popupInfoFeature.properties.index === feature.properties.index) ? 'poi-marker active': 'poi-marker'}>
                <img src={`/map/${context}_${feature.properties.icon}.svg`} alt="" width="26" height="36" className={`poi-pin-marker`}/>
                <div className={`poi-title-marker`}>
                  {feature.properties.title}
                </div>
              </div>
            </Marker>
          )
        })
      )
    },
    [pois, popupInfoFeature, enabledCategoriesIndex]
  );

  return (
    <div className="map-wrap">
      <Map
        ref={mapRef}
        mapStyle="/map/map-style.json"
        initialViewState={{
          latitude: lat,
          longitude: lng,
          zoom: zoom,
          bearing: 0,
          pitch: 0
        }}
      >
        <GeolocateControl
          position="bottom-right"
        />
        <Layer
          id={`raster`}
          source="raster"
          type="raster"
          minzoom={0}
          maxzoom={15}
        />
        <Layer
          id={`osm`}
          source="osm"
          type="raster"
          minzoom={15}
          maxzoom={20}
        />
        { renderPois }
      </Map>
      {dataLoaded ?
        <CategoryFilterDialog onChange={onCategoryFilterChange} categories={categories} context={context} />
        : null
      }
      {popupInfoFeature ?
        <Box>
          <PoiInfoCard
            poi={pois[popupInfoFeature?.properties.index]}
            category={categories[popupInfoFeature?.properties.catIndex]}
            context={context}
            onClose={popupInfoClose}
            onReadMore={openPoiInfoDialog}
            sx={{
              display: (poiInfoDialogOpen ? 'none' : 'block')
            }}
          />
          <PoiInfoDialog
            key={`poi-info-dialog-${popupInfoFeature?.properties.index}`}
            poi={pois[popupInfoFeature?.properties.index]}
            category={categories[popupInfoFeature?.properties.catIndex]}
            context={context}
            open={poiInfoDialogOpen}
            onClose={onPoiInfoDialogClose}
          />
        </Box>
      : null}
      <Box className="top-left-controls">
        <Fab color="secondary" size="small" aria-label="add" onClick={handleTopLeftControlClick} sx={{ borderRadius: '4px' }}>
          {isFullscreen ?
            <CloseFullscreenIcon />
            :
            <OpenInFullIcon />
          }

        </Fab>
      </Box>
      <Box className="top-right-controls">
        <Fab color="secondary" size="small" aria-label="add" onClick={handleTopRightControlClick} sx={{ borderRadius: '4px' }}>
          {context === 'explorade' ?
            <img src={`/images/ic_${lang}.png`} width="24" height="24" />
            :
            <PlayCircleIcon />
          }
        </Fab>
      </Box>
      <Box className="app-brand">
        {context === 'explorade' ?
          <Stack direction="row" alignItems="center" gap={1} sx={{fontWeight: 300, fontSize: 16}}>
            <img src="/images/icon_explorade.png" width="40" height="40" alt="Explo'r@de"/>
            Explo'r@de
          </Stack>
          :
          <Stack direction="row" alignItems="center" gap={1} sx={{fontWeight: 300, fontSize: 16}}>
            <img src="/images/icon_parcoursmer.png" width="40" height="40" alt="Parcours mer"/>
            Parcours mer
          </Stack>
        }

      </Box>
      <LanguageSelectorDialog
        open={openLanguageSelectorDialog}
        onClose={onCloseLanguageSelectorDialog}
        selectedLang={lang}
        onChange={selectNewLang}
      />
    </div>
  );
}