import * as React from 'react';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import LayersIcon from '@mui/icons-material/Layers';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';



export interface CategoryFilterDialogProps {
  categories: any;
  context: string;
  onChange: (enabledCategories: boolean[]) => void;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const CustomDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 18,
            top: 15,
            color: '#FFF',
            backgroundColor: '#004272',
            width: 20,
            height: 20
          }}
        >
          <CloseIcon
            sx={{
              width: 16
            }}
          />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CategoryFilterDialog(props: CategoryFilterDialogProps) {
  const categoriesData = props.categories;
  const context = props.context;
  const [checkedState, setCheckedState] = React.useState(
    props.categories.map((c) => { return c.enabled; })
  );
  const onChangeCbFunc = props.onChange;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);

    onChangeCbFunc(updatedCheckedState);
  };

  return (
    <div>
      <Box className="bottom-right-controls">
        <Fab color="primary" aria-label="add" onClick={handleClickOpen}>
          <LayersIcon />
        </Fab>
      </Box>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Sélectionnez un filtre
        </CustomDialogTitle>
        <DialogContent dividers sx={{ p: 1, minWidth: 300 }}>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {categoriesData.map((category, i) => {
              return (
                <ListItem
                  key={`item-${i}`}
                  disablePadding
                  divider={i != (categoriesData.length - 1)}
                >
                  <ListItemButton
                    role={undefined}
                    onClick={() => { handleOnChange(i); }} dense
                    divider={i != (categoriesData.length - 1)}
                  >
                    <ListItemText id={category.icon} primary={
                      <Stack direction="row" alignItems="center" gap={1} sx={{fontWeight: 300, fontSize: 16}}>
                        <i
                          className={`ic_${context}_${category.icon}`}
                          style={{
                            fontSize:24,
                            color: '#FFF',
                            backgroundColor: category.color,
                            borderRadius: '50%'
                          }}
                        />
                        { category.name }
                      </Stack>
                    } />
                    <ListItemIcon>
                      <Checkbox
                        edge="end"
                        checked={checkedState[i]}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': category.icon }}
                        icon={ <RadioButtonUncheckedIcon /> }
                        checkedIcon={ <CheckCircleIcon /> }
                      />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
}