import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import parse from 'html-react-parser';


export interface PoiInfoCardProps {
  poi: any;
  category: any;
  sx: any;
  context: string;
  onClose: () => void;
  onReadMore: () => void;
}

export interface CardTitleProps {
  children?: React.ReactNode;
  onClose: () => void;
}

const CustomCardTitle = (props: CardTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      {...other}
      sx={{
        backgroundColor: "#FFF",
        color: '#000',
        textAlign: 'left',
        paddingLeft: '16px'
      }}
    >
      <Box component="div" sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical'
      }}>
        {children}
      </Box>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 18,
            top: 15,
            color: '#000',
            border: '2px solid #000',
            width: 20,
            height: 20
          }}
        >
          <CloseIcon
            sx={{
              width: 16
            }}
          />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function PoiInfoCard(props: PoiInfoCardProps) {
  const poi = props.poi;
  const category = props.category;
  const sx = props.sx;
  const context = props.context;
  const onClose = props.onClose;
  const onReadMore = props.onReadMore;

  const handleReadMore = () => {
    onReadMore();
  };

  let image_src = (context === 'explorade' ? poi.image_small_url : poi.image_small);

  return (
    <Card className="poi-info-card" sx={{...sx}}>
      <CustomCardTitle
        onClose={onClose}
      >
        {poi.name}
      </CustomCardTitle>
      <CardContent
        sx={{
          backgroundColor: '#FFF',
          pt: 0,
        }}
      >
        {category ?
        <Stack direction="row" alignItems="center" gap={1} sx={{fontWeight: 700, fontSize: 15}}>
          <i
            className={`ic_${context}_${category.icon}`}
            style={{
              fontSize:24,
              color: '#FFF',
              backgroundColor: category.color,
              borderRadius: '50%'
            }}
          />
          <span style={{ color: category.color }}>
            { category.name }
          </span>
        </Stack>
        : null}
        <Stack className="poi-description" direction="row" alignItems="flex-start" gap={1} sx={{fontWeight: 300, fontSize: 16, pt: 2}}>
          {
            image_src ?
              <img src={image_src} style={{ height: 'auto' }} />
              : null
          }
          {
            poi.short_description ?
              <div>
                {parse(poi.short_description)}
              </div>
              : null
          }
        </Stack>
        <Box textAlign="center">
          <Button variant="text" onClick={handleReadMore}>En savoir plus</Button>
        </Box>
      </CardContent>
    </Card>
  );
}