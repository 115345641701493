import * as React from 'react';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import LayersIcon from '@mui/icons-material/Layers';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';



export interface LanguageSelectorDialogProps {
  selectedLang: any;
  open: boolean;
  onChange: (newLang: string) => void;
  onClose: () => void;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const CustomDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 18,
            top: 15,
            color: '#FFF',
            backgroundColor: '#004272',
            width: 20,
            height: 20
          }}
        >
          <CloseIcon
            sx={{
              width: 16
            }}
          />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function LanguageSelectorDialog(props: LanguageSelectorDialogProps) {
  const selectedLang = props.selectedLang;
  const onClose = props.onClose;
  const open = props.open;

  const languages = [
    { name: 'Brezoneg', code: 'br'},
    { name: 'English', code: 'en'},
    { name: 'Français', code: 'fr'}
  ];

  const onChangeCbFunc = props.onChange;

  const handleClose = () => {
    onClose();
  };

  const handleOnChange = (lang) => {
    onChangeCbFunc(lang);
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Sélectionnez une langue
      </CustomDialogTitle>
      <DialogContent dividers sx={{ p: 1, minWidth: 300 }}>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {languages.map((language, i) => {
            return (
              <ListItem
                key={`item-${i}`}
                disablePadding
                divider={i != (languages.length - 1)}
              >
                <ListItemButton
                  role={undefined}
                  onClick={() => { handleOnChange(language.code); }} dense
                  divider={i != (languages.length - 1)}
                >
                  <ListItemText id={language.code} primary={
                    <Stack direction="row" alignItems="center" gap={1} sx={{fontWeight: 300, fontSize: 16}}>
                    <img src={`/images/ic_${language.code}.png`} width="24" height="24" />
                      { language.name }
                    </Stack>
                  } />
                  <ListItemIcon>
                    <Checkbox
                      edge="end"
                      checked={selectedLang === language.code}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': language.code }}
                      icon={ <RadioButtonUncheckedIcon /> }
                      checkedIcon={ <CheckCircleIcon /> }
                    />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
}