import { createTheme } from "@mui/material/styles";


// @ts-ignore
export default createTheme({
  palette: {
    primary: {
      light: '#005FA5',
      main: '#005FA5',
      dark: '#005FA5',
      contrastText: '#FFF',
    },
    secondary: {
      light: '#FFFFFF',
      main: '#FFFFFF',
      dark: '#EEEEFF',
      contrastText: '#005FA5',
    },
    error: {
      main: '#85253C'
    },
  },
  typography: {
    fontFamily: 'Open Sans',
    h5: {
      fontWeight: 900,
      fontSize: '1.1em'
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 24,
          textTransform: 'none',
          fontWeight: 900,
        },
        outlinedError: {
          borderWidth: 2,
          borderColor: '#85253C',
          '&:hover': {
            borderWidth: 2
          }
        }
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 20
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 20,
          display: 'flex',
          justifyContent: 'space-around',
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: '#005FA5',
          color: '#FFF',
          textAlign: 'center',
          height: 30,
          paddingTop: 10,
          paddingBottom: 10,
          fontFamily: 'Squada One'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: '#F5F5F5',
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'space-around',
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#F2F4F8',
          '&:hover': {
            backgroundColor: '#EBEFF7'
          }
        }
      }
    }
  }
});